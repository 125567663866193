<template>
  <div class="ui grid container-6">
    <div class="two column row stackable">
      <div class="column div-1">
        <img src="@/assets/wn6.png">
      </div>
      <div class="column div-2">
        <div class="content">
          <h1><span>What is</span> QR PH?</h1>
          <p>
            QR PH is the National QR Code Standard
            in the use of the Quick Response code technology
            of banks, non-bank electronic  money issuers,  like TayoCash, and participating merchants and partners for digital fund transfers and payments. It is the initiative and  requirement by the BSP  to promote an interoperable common QR Code that can be scanned and accepted for quick and safe fund transfer and payment requests.
          </p>

          <h1><span>Do I Have a </span>TayoCash QR PH Code?</h1>
          <p>
            Yes, your TayoCash e-wallet account is assigned a unique TayoCash QR PH Code and it can be used and accepted for fund transfers and payments.  You can view, share and save your TayoCash QR PH Code from your TayoCash Mobile App Dashboard.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content2'
}
</script>

<style scoped lang='scss'>
</style>
